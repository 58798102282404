body{
    font-size: 16px;
    color: #0E243A;
    overflow-x: hidden;
}
#headerMain .fadeInDown {
    box-shadow: 0px 3px 10px #21252912;
    padding: 0;
    background: #0e243a;
    background: -webkit-linear-gradient(to right, #185490, #0e243a);
    background: linear-gradient(to right, #185490, #0e243a);
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #3ABE4C;
}
.navbar{
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0;
    min-width: 100px;
    width: 100%;
    border: 0;
    box-shadow: 0px 5px 15px #2e2c3830;
}
.dropdown .dropdown-menu {
    transition: all 0.5s;
    overflow: hidden;
    transform-origin: top center;
    transform: scale(1, 0);
    display: block;
}
.dropdown:hover .dropdown-menu {
    transform: scale(1);
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
    background-color: #362a78;
    color: #fff;
}
.dropdown .dropdown-item {
    padding: 10px 20px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
}
.btn-rounded{
   border-radius: 20rem;
}
.btn-high {
    background: #f07f2d;
    border-radius: 4px;
    box-shadow: 0 2px 6px 1px #400505;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 16px;
    padding: 9px 7px !important;
    font-weight: 500;
    position: relative;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(58 190 76);
}
nav.navbar.fixed-top {
    top: 30px;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0.2em solid;
    border-right: .2em solid transparent;
    border-bottom: 0;
    border-left: .2em solid transparent;
}
.dropdown:hover .dropdown-menu{
    display: block;
}
.navbar-light .navbar-nav .nav-link::before{
    width: 0;
}
.navbar-light .navbar-nav .nav-link.active::before, .navbar-light .navbar-nav .nav-link:hover::before {
    content: "";
    width: 30px;
    height: 3px;
    background: #3ABE4C;
    position: absolute;
    bottom: 0px;
    border-radius: 20rem;
}
.navbar-light .navbar-nav .nav-link::before, .navbar-light .navbar-nav .nav-link::before {
    content: "";
    width: 0px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 5px;
}
.owl-theme .owl-dots .owl-dot.active span {
    background: #009641;
    width: 30px;
}
.owl-theme .owl-nav.disabled+.owl-dots {
    position: absolute;
    bottom: 50px;
    width: 100%;
    z-index: 2;
}
.flex-group {
    display: flex;
    align-items: center;
    padding: 125px 0px;
}
@media (max-width: 650px){
    .flex-group {
        display: flex;
        align-items: center;
        padding: 0px;
   }
}
@media (max-width: 650px){
    .bnrimage{
        display:none;
   }
}
/* bnrimage */
@media (max-width: 650px){
    .slide-head {
        font-weight: 600;
        color: #fff;
        margin-bottom: 30px;
        font-size: 15px;
   }
   .slide-tag {
        color: #fff;
        font-size: 10px;
        margin-bottom: 15px;
   }
}
.slide-tag {
    color: #fff;
    font-size: 22px;
    margin-bottom: 30px;
}
.btn {
    padding-left: 30px;
    padding-right: 30px;
}
.btn-success {
    color: #fff;
    background-color: #ef7e2d !important;
    border-color: #ef7e2d !important;
    border-radius: 25px !important;
}
.btn-success:hover{
    color: #fff;
    background-color: #3abe4c !important;
    border-color: #3abe4c !important;
    border-radius: 25px !important;
}
.flex-item:nth-child(2) {
    width: 100%;
}
.flex-item {
    width: 80%;
    margin-right: 30px;
}
.section-wrap {
    padding: 100px 0px;
}
.slide-wrap::before {
    content: "";
    background: url('../src/Components/assets/img/banner-shape.svg') no-repeat;
    background-position: right;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
}
.head-wrap p {
    font-size: 20px;
    color: #1b1b1b;
}
.green-bg {
    background: #3abe4c;
}
.flexItem .green-bg{
    color: #fff;
    display: inline-block;
    padding: 4px 20px;
    font-size: 14px;
}
.flexGroup {
    display: flex;
    align-items: center;
}
.cardBx {
    text-align: center;
    box-shadow: 0px 20px 140px #eee;
    background: #fff;
    margin: 10px;
    margin-top: 50px;
    padding: 20px;
    max-width: 225px;
    border-radius: 10px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.cardBx:hover, .tips-item:hover{
    box-shadow: none;
}
.section-wrapper {
    padding: 40px 0px;
    position: relative;
    overflow: hidden;
}
.about-company::before {
    content: "";
    background: url('../src/Components/assets/img/circle-shape.svg') no-repeat;
    background-size: 100%;
    width: 500px;
    height: 500px;
    position: absolute;
    left: -14%;
    top: 20%;
    animation: float 6s ease-in-out infinite;
    z-index: -1;
}
.about-company::after {
    content: "";
    background: url('../src/Components/assets/img/multi-cirlce-shape.svg') no-repeat;
    background-size: 100%;
    width: 400px;
    height: 400px;
    position: absolute;
    right: -14%;
    bottom: 20%;
    z-index: -1;
    animation: float2 8s ease-in-out infinite;
}
@keyframes float {
    0% {
        transform: translatey(0px);
   }
    50% {
        transform: translatey(-60px);
   }
    100% {
        transform: translatey(0px);
   }
}
@keyframes float2 {
    0% {
        transform: translatey(0px);
   }
    50% {
        transform: translatex(-80px);
   }
    100% {
        transform: translatey(0px);
   }
}
.spacing {
    letter-spacing: 4px;
}
.cardBx img {
    position: relative;
    margin-top: -54px;
}
.cardBx h3 {
    font-size: 18px;
    color: #3abe4c;
    margin: 6px 0px;
}
.cardBx p {
    font-size: 14px;
}
.head1 {
    color: #0E243A;
    margin: 24px 0px;
    font-weight: 700;
}
.think-wrap .head1 {
    font-size: 34px;
}
.head3 {
    font-size: 32px;
    margin-top: 30px;
    font-weight: 700;
    color: #0E243A;
}
.head5 a{
    text-decoration: none;
    color: #0E243A;
}
.head5 h5{
   font-size: 20px;
}
.head5{
    padding-left: 25px !important;
}
.secondary-color {
    color: #ef7e2d;
}
.think-wrap p {
    font-size: 22px;
    color: #333;
}
/*about*/
.secondary-shape, .section-grid, .joinus{
    position: relative;
}
.section-grid:nth-child(1)::before{
    content: "";
    background: url('../src/Components/assets/img/dot-shape.svg') no-repeat center center;
    width: 260px;
    height: 160px;
    background-size: 100%;
    position: absolute;
    top: 50px;
    left: -150px;
}
.section-grid:nth-child(1)::after{
    content: "";
    background: url('../src/Components/assets/img/primary-shape.svg') no-repeat center center;
    width: 400px;
    height: 400px;
    background-size: 100%;
    position: absolute;
    top: 50px;
    right: -150px;
    z-index: -1;
}
.section-grid:nth-child(2)::before{
    content: "";
    background: url('../src/Components/assets/img/circle-shape.svg') no-repeat center center;
    width: 400px;
    height: 400px;
    background-size: 100%;
    position: absolute;
    top: 50px;
    left: -150px;
}
.section-grid:nth-child(2)::after {
    content: "";
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px dashed #E4BBF2;
    position: absolute;
    bottom: 34px;
    left: 9px;
    z-index: 1;
}
.joinus::before{
    content: "";
    background: url('../src/Components/assets/img/dot-shape.svg') no-repeat center center;
    width: 260px;
    height: 460px;
    background-size: 100%;
    position: absolute;
    top: -20px;
    left: -150px;
}
.impact{
    background: url('../src/Components/assets/img/ric-logo.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
}
.video-cover img {
    width: 100%;
}
.play-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    font-size: 50px;
    color:#009641;
}
.play-btn img {
    width: 80px !important;
}
.video-list .play-btn img {
    width: 60px !important;
}
.news-card .video-cover {
    height: 210px;
    border-radius: 15px;
}
.video-btn {
    text-decoration: none;
}
.news-card h3 {
    font-size: 22px;
    line-height: 28px;
}
/* .partners-list {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 15px #eae8e8;
    margin: 20px;
}
*/
.partners-list {
   /* width: 150px;
    height: 150px;
    */
    width: 165px;
    height: 110px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px #a8a8a8;
    margin: 10px;
}
.partners-list img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.video-cover {
    width: 100%;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 0px 30px #cc601359;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
}
.video-cover:hover {
    box-shadow: 0px 0px 100px #8c4410a1;
    transform: scale(1.3);
    -webkit-transform: scale(1.1);
}
.flex-center {
    display: flex;
    align-items: center;
    height: 100%;
}
.videos-group .modal-body {
    padding: 0;
}
.videos-group .modal-content {
    border-radius: 20px;
    overflow: hidden;
}
.videos-group .btn-close {
    position: absolute;
    right: 24px;
    background-color: #fff !important;
    width: 30px;
    height: 30px;
    border-radius: 20rem;
    top: 14px;
    opacity: 1.0;
}
.video-list {
    margin: 40px 0px;
}
.impact{
    position: relative;
}
.impact::after {
    content: "";
    background: url('../src/Components/assets/img/impact-shape.svg') no-repeat;
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    right: 0;
    background-position: right;
    z-index: -1;
}
.impact::before {
    content: "";
    background: url('../src/Components/assets/img/ric-logo.png') no-repeat;
    width: 100%;
    height: 246px;
    position: absolute;
    bottom: 100px;
    left: 0;
    background-position: left;
    z-index: -1;
}
#news-carousel .video-cover {
    margin: 30px 0px;
}
#news-carousel .video-cover:hover{
    transform: none;
    box-shadow: none;
}
.head4 {
    font-size: 20px;
    color: #333;
    font-weight: 400;
    margin: 20px 0px;
}
.wrapper-content {
    position: relative;
    z-index: 2;
    font-size: 20px;
}
.grey-bg{
    background: #F8F8FC;
}
.about-flex {
    align-items: baseline;
}
.tips-item {
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 10px 40px #eee;
    margin-bottom: 30px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.tips-item h3 {
    font-size: 18px;
    color: #2B2350;
    font-weight: 700;
}
.tips-item-img{
    position: relative;
    margin-bottom: 20px;
}
.tips-item p{
    font-size: 14px;
}
.tips-item p, .tips-item h3{
    padding: 0px 20px;
}
.btn-warning {
    color: #fff;
    background-color: #F18A42 !important;
    border-color: #F18A42 !important;
}
.btn-warning:hover {
    color: #fff;
    background-color: #3bbe4d !important;
    border-color: #3bbe4d !important;
}
.btn.btn-rounded img {
    position: relative;
    top: -2px;
}
.flexItem:nth-child(1) {
    max-width: 500px;
    margin-right: 30px;
}
.flex-close {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer {
    background: #E7F0FD;
    color: #0E243A;
    padding: 50px 0px;
    font-size: 16px;
    position: relative;
    margin-top: 50px;
}
@media screen and (max-width: 528px){
    .footer {
       /* background: #E7F0FD;
        color: #0E243A;
        padding: 50px 0px;
        font-size: 16px;
        position: relative;
        margin-top: 50px;
        */
        margin-top: 10%;
        overflow: hidden !important;
   }
}
.footer a {
    color: #0E243A;
    text-decoration: none;
}
.footer::before {
    content: "";
    background: url('../src/Components/assets/img/footer-shape.svg') no-repeat;
    position: absolute;
    left: 0;
    top: -100px;
    width: 100%;
    height: 112px;
    background-size: cover;
}
.socials {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.socials li{
   margin-right: 10px;
}
.socials li a svg{
   transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.socials li a svg:hover path {
    fill: #22b361;
}
.service-item a {
    padding: 8px 0px;
    display: inline-block;
}
.footer-item h3 {
    font-size: 20px;
}
.socials li a svg:hover {
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
}
.service-item a:hover {
    color: #0dab5a;
}
.subscribe .form-group {
    display: flex;
    max-width: 350px;
}
.subscribe .form-group input:focus{
   box-shadow: none;
   outline: none;
   background: #fff;
}
.socials li a svg path {
    fill: #2B2350;
}
.flex-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright{
   font-size: 16px;
   padding: 10px 0px;
}
.copyright-terms ul li {
    float: left;
}
.copyright-terms ul li a{
    text-decoration: none;
    color: #333;
    padding: 10px 20px;
}
.slide-wrap {
    position: relative;
    background: url('../src/Components/assets/img/banner-bg.svg') no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 400px;
}
/*inner page*/
.inner{
    /* background: linear-gradient(to right, #0e243a, #185490 ); */
    background: linear-gradient(to right, #020e1a, #185490 );
    padding: 70px 0px;
}
.max-img img{
   border-radius: 40px;
}
.max-img.top-shape::before {
    top: -11%;
    left: 69%;
}
.page-heading h3 {
    font-weight: 700;
    color: #ffffff;
    font-size: 3rem;
}
.page-heading .content {
    max-width: 450px;
    color: #fff;
}
.page-img img {
    width: 300px;
    margin-right: 50px;
}
@media screen and (max-width: 768px) and (min-width: 585px) {
    .page-img img {
        width: 300px;
        margin-right: 50px;
        display: none;
   }
}
.page-img {
    margin-right: 50px;
}
.subscribe-wrap h1 {
    margin-bottom: 20px;
}
.icon {
    width: 38px;
    height: 60px;
    /* background: #fff;
    box-shadow: 0px 0px 15px #595d6526; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-right: 10px; */
}
.contact_form .form-control:focus {
    outline: none;
    box-shadow: 0px 0px 20px #3abe4c42;
}
.contact-list {
    display: flex;
    margin: 20px 20px 20px 0px;
}
.contact-row {
    display: flex;
    align-items: center;
}
.icon svg {
    height: 25px;
}
.contact-list a {
    color: #362a78;
    text-decoration: none;
    margin-top: 20px;
    display: block;
}
.contact_form {
    background: #F7F6F9;
    padding: 0px 20px;
    border-radius: 10px;
    /* margin-top: 30px; */
}
.form-group label {
    color: #252F3D;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.form-group{
   margin-bottom: 20px;
}
.red-text {
    color: red;
}
.inner .flex-grid {
    justify-content: space-between;
    margin: 50px 0px;
}
.profile {
    text-align: center;
    margin-bottom: 30px;
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 20px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.profile:hover {
    box-shadow: 0px 0px 80px #14437126;
    -webkit-box-shadow: 0px 0px 80px #14437126;
    -moz-box-shadow: 0px 0px 80px #14437126;
    -o-box-shadow: 0px 0px 80px #14437126;
}
.team-cover {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    margin-bottom: 20px;
}
.team-cover img {
    border-radius: 50%;
    position: relative;
   /* top: 26px;
    */
}
.profile h5 {
    font-size: 16px;
    color: #ee7d2d;
}
.hins {
    min-height: 90px;
    color: #83888a;
}
/*colors*/
.light-green{
    background: #B9F6CA;
}
.light-amber{
    background: #FFECB3;
}
.light-blue{
    background: #E1F5FE;
}
.light-purple{
    background: #EDE7F6;
}
.light-pink{
    background: #FCE4EC;
}
.light-indigo{
    background: #E8EAF6;
}
.light-red{
    background: #FFEBEE;
}
.light-teal{
    background: #E0F2F1;
}
/*bg*/
/*gallery*/
.gallery-item {
    height: 160px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
    cursor: pointer;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    display: inline-block;
}
.gallery-item:hover {
    box-shadow: 0px 0px 50px #163a5f7d;
    -webkit-box-shadow: 0px 0px 50px #163a5f7d;
    -moz-box-shadow: 0px 0px 50px #163a5f7d;
    -o-box-shadow: 0px 0px 50px #163a5f7d;
    transform: rotate3d(1, 2, 1, 6deg);
    -webkit-transform: rotate3d(1, 2, 1, 6deg);
}
#gallery-container{
    display: inline-block;
    text-align: center;
}
.navbar-light .navbar-toggler {
    color: rgb(255 255 255 / 55%);
    border-color: rgb(255 255 255 / 10%);
    background: #fff;
   /* transition: box-shadow .15s ease-in-out;
    */
}
@media (max-width: 650px) {
    .navbar-light .navbar-toggler{
        display: block !important;
   }
    .inner .flex-grid {
        display: block;
        text-align: center;
   }
   .page-heading h3{
       font-size: 2rem;
   }
   .page-img {
        margin-right: 0;
   }
   .content {
        padding: 20px;
   }
   .flexBx {
        padding: 20px;
   }
   .section-wrapper.page-wrap {
        padding: 20px;
   }
   .section-wrapper .row.mt-5.justify-content-center {
        margin-top: 0 !important;
   }
   .address {
        padding: 0px 30px;
   }
   .contact-row {
        display: block;
        align-items: center;
   }
   .gallery-item {
        height: 70px;
        margin: 2px;
        width: 100px;
   }
   .page-img img {
        margin-right: 0;
        margin-top: 25px;
   }
    .inner::before {
        display: none;
   }
    .video-cover {
        margin: 20px 0px;
   }
    .flex-item {
        width: 100%;
        margin: 0;
        padding: 20px;
   }
    .flex-group {
        display: flex;
       /* padding: 125px 0px;
        */
        align-items: center;
   }
    .owl-theme .owl-nav.disabled+.owl-dots {
        bottom: 73px;
   }
   .flex-close {
        display: block;
        padding: 20px;
   }
   .flexItem:nth-child(1) {
        margin: 0;
   }
   .flexGroup {
        display: block;
        align-items: center;
   }
   .cardBx{
       max-width: 100%;
   }
   .think-wrap .head1, .head3 {
        font-size: 26px;
   }
   .think-wrap p {
        font-size: 18px;
   }
    .wrapper-content{
       font-size: 16px;
   }
    .secondary-shape, .section-grid, .joinus {
        padding: 20px;
   }
   .head-wrap {
        padding: 0px 20px;
   }
   .video-list {
        margin: 0px 10px;
   }
   .news-card {
        padding: 20px;
   }
   .latestnews{
        padding: 20px;
   }
    section.section-wrapper.partners {
        padding: 0;
   }
   .partners-list{
       margin: 10px;
   }
}
@media (max-width: 992px){
    .slide-tag {
        font-size: 14px;
   }
   .flex-item:nth-child(2) {
        width: 75%;
        margin: 0;
   }
   .slide-head {
        font-size: 20px;
   }
   .btn-success{
       font-size: 18px;
   }
   .flex-close {
        display: block;
        padding: 20px;
   }
   .flexItem:nth-child(1) {
        margin: 0;
   }
   .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        background: #02182d;
        margin-bottom: 13px;
   }
   .navbar-nav-scroll {
        max-height: 100%;
   }
   .navbar-light .navbar-nav .nav-link{
        font-size: 16px;
   }
}
.btn-high{
    background: #f07f2d;
    border-radius: 4px;
    box-shadow: 0 2px 6px 1px #400505;
}
.btn-high:hover{
    background: white !important;
    border-radius: 4px;
}
/* topheader start */
.ticker-news {
    background: #1c3e5f;
    height: 30px;
    border: 1px solid #15314d;
}
.ticker-news span{
    display:none;
    background: #dd3737;
    height:40px;
    float: left;
    padding: 0 12px;
    color: #ffffff;
    font-size:14px;
    font-weight:700;
    text-transform: uppercase;
    line-height:4
}
/* topheader end */
/*footer start*/
.footerbtn{
    color: #fff !important;
    background-color: #f07f2d;
    border-color: #f07f2d;
}
/*footer end*/
/* #headerMain .fadeInDown {
    background: transparent;
}
*/
/*top header start*/
.ticker-news {
    background: #1c3e5f;
    height: 30px;
    border: 1px solid #15314d;
    background: #fff;
   /* height: 40px;
    */
    overflow: hidden;
    position: relative;
   /* border: 1px solid #ddd;
    */
    border-radius: 0;
    margin: 0 ;
    padding: 0;
    line-height: 20px;
}
.ticker-news span {
    display: none;
}
.ticker-news span {
    background: #dd3737;
    height: 40px;
    float: left;
    padding: 0 12px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 40px;
}
/* .ticker-news .tickercontainer ul.newsticker li, .ticker-news .tickercontainer {
    background: #20466b;
} */
.ticker-news .tickercontainer {
    height: 34px;
    margin: 0;
    padding: 0;
    overflow: hidden;
   /* background: #fff;
    */
    position: relative;
}
.ticker-news .tickercontainer .ticker-mask {
    width: 100%;
}
.ticker-news .tickercontainer .ticker-mask {
    position: relative;
   /* width: 99%;
    */
    left: 0;
    top: 0;
    overflow: hidden;
}
.ticker-news .tickercontainer ul.newsticker {
    position: relative;
   /* left: calc(100% - 1px);
    */
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}
.ticker-news .tickercontainer ul.newsticker li, .ticker-news .tickercontainer {
    background: #20466b !important;
    /* background:linear-gradient(to right, #0e243a, #185490);  */
   /* color:white !important;
    */
}
.ticker-news .tickercontainer ul.newsticker li {
    float: left;
    margin: 0 50px 0 0;
   /* padding: 0;
    */
    list-style: none;
    list-style-image: none;
    background: #fff;
    font-size: 14px;
    font-weight: 600;
}
.ticker-news ul li {
    height: 30px;
    line-height: 29px;
}
.ticker-news .tickercontainer ul.newsticker li a {
    font-size: 14px;
    color: #fff;
}
.ticker-news .tickercontainer ul.newsticker li a {
    white-space: nowrap;
    padding: 0;
    color: #696969;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}
.ticker-mask {
    overflow: hidden;
}
.newsticker {
    display: inline-block;
    white-space: nowrap;
    animation: tickerAnimation linear infinite;
}
@keyframes tickerAnimation {
    from {
        transform: translateX(100%);
   }
    to {
        transform: translateX(-100%);
   }
}
.ticker-news a {
    color: #ffffff !important;
   /* Replace with your desired color value */
}
.scrolled {
    background-color: #ff0000;
   /* Replace with your desired background color */
}
/*top header end*/
/*header start*/
.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
}
a.navbar-brand .img-fluid {
    max-width: 100%;
    height: 86px;
    
}
.lazyloaded {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
}
/*header end*/
#home-carousel .flex-item img {
    height: 300px;
    width: auto;
    margin: auto;
}
a.carousel-control-prev{
    display: none;
}
a.carousel-control-next{
    display: none;
}
/* .body.shrink nav.navbar.fixed-top{
    top:0px;
}
*/
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
/* .navbar{
    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
   }
}
*/
/* .bd-navbar {
    min-height: 4rem;
    background-color: #563d7c;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
}
*/
/* nav.navbar.fixed-top{
   position:sticky;
   background: #0e243a ;
}
*/
/* #headerMain .fadeInDown {
    background: linear-gradient(to right, #0e243a, #185490 );
}
*/
nav.navbar.fixed-top {
    top: 30px;
}
/* .back-to-top.active {
    visibility: visible;
    opacity: 1;
    background: rgb(158,0,0) !important;
    float: right;
    width: 35px;
    height: 35px;
    position: sticky;
}
*/
/* .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f8f8;
    padding: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
}
*/
.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
}
.back-to-top:hover {
    opacity: 0.8;
}
.scroll-to-top-icon {
    width: 20px;
    height: 20px;
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
/* sticky social icon bar start */
.icon-bar {
    position: fixed;
    top: 46%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
}
@media screen and (max-width: 868px) and (min-width: 0px){
    .icon-bar {
        position: absolute;
        top: 29px;
        display: flex;
        justify-content: center;
        transform: translateX(73%);
   }
   a.navbar-brand .img-fluid {
    max-width: 100%;
    height: 60px;
    margin-top: 10%;
}
.navbar-toggler-icon {
    display: flex;
    width: 20px;
    height: 20px;
    /* vertical-align: middle; */
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.navbar-light .navbar-toggler{
    height: 30px;
    width: 39px;
    padding: 0px;
    /* margin: 27px 10px 10px 0px; */
}
    .icon-bar a {
        display: inline-block;
       /* margin-right: 10px;
        */
        color: #000;
        display: block;
        text-align: center;
        padding: 3px !important;
        transition: all 0.3s ease;
        color: white;
        font-size: 20px !important;
        width: 40px !important;
        height: 40px !important;
   }
    .banner-desc h2{
        font-size: 20px !important;
        font-weight: 600 !important;
        margin-bottom: 15px !important;
        color:white !important;
        word-wrap: break-word;
   }
    .banner-desc p{
        font-size: 15px !important;
        font-weight: 300 !important;
        margin-bottom: 15px !important;
        color:white !important;
        word-wrap: break-word;
   }
    .flex-item {
        width: 100%;
        margin: 0;
        padding: 26% 2% 10%;
   }
    .slide-wrap {
        min-height: 315px;
   }
}
@media screen and (max-width: 1200px) and (min-width: 868px){
    .icon-bar {
        position: absolute;
        top: 23%;
        /* display: flex; */
        /* justify-content: center; */
        /* transform: translateX(65%); */
   }
}
/* @media screen and (max-width: 1400px) and (min-width: 1200px){ */
@media screen and (min-width: 1200px){
    .icon-bar {
        position: fixed;
        top: 47%;
        /* display: flex; */
        /* justify-content: center; */
        /* transform: translateX(65%); */
   }
   .banner-desc h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
    color: white !important;
    word-wrap: break-word;
    /* padding-left: 20px; */
}
.banner-desc p {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
    color: white;
    word-wrap: break-word;
    /* padding-left: 38px; */
}
.flex-item {
    width: 100%;
    margin-right: 25px;
}
}
.icon-bar a {
    display: block;
    text-align: center;
    padding: 12px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    width: 54px;
    height: 54px;
}
.icon-bar a:hover{
   /* background-color: #000;
    */
    font-size: 22px;
    width: 64px;
    height: 54px;
}
.icon-bar .facebook {
    background: #3B5998;
    color: white;
}
.twitter {
    background: #55ACEE;
    color: white;
}
.instagram {
    background: #bc2a8d;
    color: #fff;
}
/* .linkedin {
    background: #007bb5;
    color: white;
}
*/
.youtube {
    background: #e51b1b;
    color: white;
}
/* sticky social icon bar end */
table {
    caption-side: bottom;
    border-collapse: collapse;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border: 1px solid #a8a5a5 !important;
}
.TotalSoftcenter {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    float: left;
}
.TotalSoftGV_LVG_LM210 {
    background-color: #999999;
    color: #ffffff;
    font-size: 20px;
    font-family: Gabriola;
    border: 1px solid#ffffff;
    cursor: pointer;
    display: block;
    padding: 3px !important;
    line-height: 1 !important;
}
.teampara{
    margin-bottom: 8px !important;
    font-size: 13px !important;
}
.teambtn{
    margin-bottom: 20px;
    background-color: #B9F6CA;
    border: 1px solid #a3a3a3;
    color: #6a6a6a;
    font-weight: 500;
    border-radius: 5px;
    font-size: 12px;
   /* border: gray;
    */
}
.teambtn:hover{
    background-color: #96e6ad;
    color: #494949;
}
.paradesg{
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.paginatedsgn{
    align-items: center;
    display: flex;
    text-decoration: none;
    list-style: none;
    margin-left: 18%;
    column-gap: 1%;
    text-align: center;
    width: 67%;
   /* border: 2px solid red;
    */
    padding-left: 20%;
}
.active-page{
    background-color: #5ea1d1;
    color: #fff;
    padding: 0px 5px;
}
.newsdesg{
    width:300px !important;
    height: 250px !important;
   /* padding-left: 5% !important;
    */
}
/* .latestdesg{
    width: 300px;
}
*/
/* .slide-head .wow .fadeInUp .p{
   font-size: 25px;
   font-weight: 400;
}
.slide-head .wow .fadeInUp .h2{
   font-size: 30px;
   font-weight: 700;
}
*/
.banner-desc h2{
    font-size: 30px ;
    font-weight: 700 ;
    margin-bottom: 30px;
    color:white !important;
    word-wrap: break-word;
}
.banner-desc p{
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
    color:white;
    word-wrap: break-word;
}
.text-color-p p{
    color:white;
    word-wrap: break-word;
    max-width: 450px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
}
.text-color-p h2{
    font-weight: 700;
    color: #ffffff;
    font-size: 3rem;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.disclaimpara h6{
    text-align: center;
}
.img.wp-smiley, img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}
.ini-summary{min-height: 50px;}
.inner1{
    /* background: linear-gradient(to right, #0e243a, #185490 ); */
    background: linear-gradient(to right, #020e1a, #185490 );
    }
.disclaimertext h5{
    text-align: center;
}
.disclaimertext p{
    text-align: justify;
}
img.wp-smiley,img.emoji{
    display: inline !important;
    border: none!important;
    box-shadow: none !important;
    height: 
1em
!important;
    width: 
1em
 !important;
    margin: 0 0.07em
!important;
    vertical-align: -0.1em
 !important;
    background: none !important;
    padding: 0 !important;
}


/* TICKER NEWS START */
.ticker-news .ticker-mask {
    overflow: hidden;
  }
  
  .ticker-news .tickercontainer {
    position: relative;
    white-space: nowrap;
  }
  
  .ticker-news .newsticker {
    list-style: none;
    padding: 0;
    margin: 0;
    animation: tickerAnimation 20s linear infinite; 
  }
  
  .ticker-news.ticker-paused .newsticker {
    animation-play-state: paused;
  }
  
  @keyframes tickerAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
/* TICKER NEWS END */